<template>
  <div class="stations container">
    <div>
      <div class="headerbar">
        <div class="info">
          <h2 class="f3">{{ $t('Reordering Stations and Experiences') }}</h2>
          <i18n
            tag="p"
            class="f5"
            path="Changes to the order of this list will be reflected in your {stationMonitorLink} and your {reservationWidgetsLink}."
          >
            <template #stationMonitorLink>
              <a class="link-style" :href="stationMonitorLink">{{ $t('Station Monitor') }}</a>
            </template>
            <template #reservationWidgetsLink>
              <router-link
                class="link-style"
                :to="{ name: 'widget-index' }"
              >{{ $t('Reservation Widgets') }}</router-link>
            </template>
          </i18n>
        </div>
        <UIButton
          tiny
          class="desktopclientbutton"
          href="https://support.springboardvr.com/hc/en-us/articles/360003063053"
          target="_blank"
          rel="noopner"
        >{{ $t('Install Desktop Client') }}</UIButton>
        <UIButton class="newstation" @click="confirmCreation" primary>{{ $t('New Station') }}</UIButton>
      </div>

      <UIRowIndexLoader v-if="isLoading" />

      <div v-if="!isLoading && !noStations" class="animated fadeIn">
        <div class="stationsheadergrid f6">
          <UIIcon class="w2 draghandle" name="reorder" />
          <div class="mobile">
            {{ $t('Experience Name') }}
            <div>- {{ $t('Station Name') }} / {{ $t('Number Of Titles') }}</div>
          </div>
          <div class="tablet">
            {{ $t('Experience Name') }}
            <div>- {{ $t('Station Name') }}</div>
          </div>
          <div class="tablet">{{ $t('Number Of Titles') }}</div>
        </div>

        <Draggable
          :value="locationExperiences"
          @input="saveExperienceOrder"
          v-bind="{ handle: '.draghandle', animation: 150, scroll: true }"
        >
          <div class="experience" v-for="experience in locationExperiences" :key="experience.id">
            <div class="experienceheadergrid f6">
              <UIIcon class="draghandle w2" name="menu" />
              <div>{{ experience.title }}</div>
            </div>

            <Draggable
              class="stationgrid"
              :value="stationsGroupedByExperience[experience.id]"
              @input="saveStationsOrder"
              v-bind="{ handle: '.draghandle', animation: 150, scroll: true }"
            >
              <div
                class="stationcard f-thead"
                v-for="station in stationsGroupedByExperience[experience.id]"
                :key="station.id"
              >
                <div class="draghandle">
                  <UIIcon class="fill-inherit" name="menu" />
                </div>
                <div class="mobile">
                  <h1 class="f-header nowrap truncate slate">{{ station.title }}</h1>
                  <div>{{ station.gamesCount }} {{ $tc('title | titles', station.gamesCount) }}</div>
                </div>
                <h1 class="tablet title f-header nowrap dib truncate slate">{{ station.title }}</h1>
                <div
                  class="numberoftitles tablet"
                >{{ station.gamesCount }} {{ $tc('title | titles', station.gamesCount) }}</div>
                <div class="edit">
                  <UIButton
                    tiny
                    icon="edit"
                    :to="{ name: 'station-show', params: { stationId: station.id } }"
                  >{{ $t("edit") }}</UIButton>
                </div>
              </div>
            </Draggable>
          </div>
        </Draggable>
      </div>

      <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noStations">
        <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
        <i18n tag="p" path="You don't have stations yet. Click on {newStationLink} to create one.">
          <template #newStationLink>
            <router-link
              class="link-style"
              :to="{ name: 'station-show', params: { stationId: 'new' } }"
            >{{ $t('New Station') }}</router-link>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Draggable from 'vuedraggable';
import { groupBy, sortBy } from 'lodash-es';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    Draggable,
    UIButton,
    UIIcon,
    UIRowIndexLoader,
  },
})
export default class StationIndexRoute extends Vue {
  created () {
    this.$store.commit('setTitle', this.$t('Stations'));
  }

  get stationMonitorLink () {
    return `//${window.sbvrenv.MONITOR_URL}/${this.locationId}/overview`;
  }

  get isLoading () {
    return this.$store.state.stations.isFetchingStations && this.locationStations.length === 0;
  }

  get noStations () {
    return !this.$store.state.stations.isFetchingStations && this.locationStations.length === 0;
  }

  get stationCount () {
    return this.$store.getters.currentOrganization.currentStationCount;
  }

  get locationStations () {
    return sortBy(this.$store.getters['entities/Station'](station => {
      if (station.location) {
        return station.location.id === this.locationId;
      }
    }), 'order');
  }

  get stationsGroupedByExperience () {
    return groupBy(this.locationStations, 'experience.id');
  }

  get locationExperiences () {
    return sortBy(this.$store.getters['entities/Experience'](experience => {
      if (experience.location) return experience.location.id === this.locationId;
    }), 'order');
  }

  saveExperienceOrder (experiences) {
    this.$store.dispatch('saveExperienceOrder', experiences.map(({ id }) => id));
  }

  saveStationsOrder (stations) {
    this.$store.dispatch('saveStationsOrder', stations.map(({ id }) => id));
  }

  confirmCreation () {
    const newStationRoute = { name: 'station-show', params: { stationId: 'new' } };

    if (this.$permissions['enterprise-operator']) {
      this.$router.push(newStationRoute);
    }

    let message;
    if (this.stationCount === 9) {
      message = this.$t('You’re about to create your 10th station, congrats! Starting next month, you will receive a volume discount on your monthly rate. After you save your new station, you will be charged the prorated amount for this month’s usage on your next invoice.');
    } else {
      message = this.$t('Adding a new station will increase your monthly subscription fees by your current per station rate. After you save your new station, you will be charged the prorated amount for this month’s usage on your next invoice.');
    }
    this.$store.commit('setConfirm', {
      buttons: [
        {
          name: this.$t('Cancel'),
          type: 'cancel',
        },
        {
          name: this.$t('Create a New Station'),
          action: () => {
            this.$router.push(newStationRoute);
          },
        },
      ],
      icon: 'icon-round-plus',
      message,
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.stations {
  padding-top: var(--spacingMd);

  & .loadinggrid {
    display: grid;
    grid-gap: var(--spacingSm);
  }

  & .headerbar {
    display: grid;
    grid-gap: var(--spacingMd);
    margin-bottom: var(--spacingMd);

    @media (--tablet) {
      align-items: center;
      grid-template-columns: minmax(auto, 50rem) 1fr auto;
    }

    & .desktopclientbutton {
      text-align: center;
      text-decoration: none;
      white-space: nowrap;

      @media (--tablet) {
        text-align: right;
      }
    }
  }

  & .stationsheadergrid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: var(--spacingSm);
    padding: 0 var(--spacingSm);
    position: relative;
    left: 0.2rem;
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      grid-gap: var(--spacingMd);
      padding: 0 var(--spacingAltMd);
      grid-template-columns: auto 1fr auto 5.5rem;
    }

    & .draghandle {
      fill: var(--colorFjord);
    }
  }

  & .experience {
    margin-bottom: var(--spacingMd);

    & .experienceheadergrid {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: 0 var(--spacingSm);
      position: relative;
      left: 0.2rem;
      color: var(--colorSlate);
      margin-bottom: var(--spacingSm);

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: 0 var(--spacingAltMd);
        grid-template-columns: auto 1fr auto 5.5rem;
      }

      & svg {
        fill: var(--colorFjord);
        transition: fill 0.4s ease-in-out;
      }
    }

    & .stationgrid {
      display: grid;
      grid-gap: var(--spacingSm);

      & .stationcard {
        background-color: var(--colorWhite);
        box-shadow: var(--shadowSm);
        border-radius: var(--radius);

        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        grid-gap: var(--spacingSm);
        padding: var(--spacingSm);

        @media (--tablet) {
          padding: var(--spacingAltSm) var(--spacingAltMd);
          grid-gap: var(--spacingMd);
          grid-template-columns: auto 1fr auto auto;

          & .edit {
            padding-left: var(--spacingMd);
          }
        }
      }
    }

    & .draghandle {
      cursor: grab;
      position: relative;
      text-align: center;
      fill: var(--colorFjord);

      & svg {
        width: 2.5rem;
      }
    }
  }
}
</style>
