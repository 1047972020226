<template>
  <RouterView />
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
})
export default class StationRoute extends Vue {
  created () {
    this.getData();
  }

  async getData () {
    this.$store.dispatch('getLocationExperiencesForStation', this.locationId);
    this.$store.dispatch('getLocationStations', this.locationId);
    this.$store.dispatch('getGamesForStation');
  }
}
</script>
